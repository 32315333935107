import clsx from "clsx";
import { motion } from "framer-motion";
import ReactHtmlParser from "react-html-parser";

const Message = ({ message }) => {
	return (
		<motion.div
			initial={{ opacity: 0, height: 0 }}
			animate={{ opacity: 1, height: "auto" }}
			exit={{ opacity: 0, height: 0 }}
			className={clsx("Message", "max-w-screen-xl mx-auto")}
		>
			<div
				className={clsx(
					"Message__content",
					"bg-yellow-01",
					"flex justify-center items-center",
					"p-7.5"
				)}
			>
				<div className={clsx("Message__icon", "mr-7.5")}>
					<svg
						width="48"
						height="48"
						viewBox="0 0 48 48"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<circle
							cx="24"
							cy="24"
							r="23"
							stroke="#0D1418"
							strokeWidth="1.5"
							strokeMiterlimit="10"
						/>
						<path
							d="M32.901 19L23.001 29.08H21.001L15.001 23.08"
							stroke="#0D1418"
							strokeWidth="1.5"
							strokeMiterlimit="10"
						/>
					</svg>
				</div>
				<div className={clsx("Message__body")}>{ReactHtmlParser(message)}</div>{" "}
			</div>
		</motion.div>
	);
};

export default Message;
