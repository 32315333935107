import "@styles/app.css";
import "@styles/globals.css";
import "intersection-observer";

import clsx from "clsx";
import { Provider as JotaiProvider } from "jotai";
import { NextComponentType, NextPageContext } from "next";
import Head from "next/head";
import { useRouter } from "next/router";

import NProgress from "nprogress";
import React, { useEffect, useState } from "react";

import Layout from "@layouts/Layout";

import Script from "next/script";

import cookieCutter from "cookie-cutter";

import SiteFooter from "@modules/SiteFooter";
import SiteMenu from "@modules/SiteMenu";
import SiteHeader from "@modules/SiteHeader";
import SiteMobileMenu from "@modules/SiteMobileMenu";
import { handleResize } from "@lib/handleResize";

NProgress.configure({ easing: "easeOut", speed: 750 });

declare global {
	interface Window {
		dataLayer: any;
	}
}

type AppProps = {
	pageProps: any;
	err: any;
	Component: NextComponentType<NextPageContext, any, {}> & { Layout: any };
};

function MyApp({ Component, pageProps, err }: AppProps): JSX.Element {
	const TargetLayout = Component.Layout ? Component.Layout : Layout;

	const router = useRouter();

	const [urlParams, setUrlParams] = useState(null);

	/*
		Kick off nprogress on route change
	*/

	useEffect(() => {
		const expireDate = new Date();
		expireDate.setDate(expireDate.getDate() + 1);
		const routerItems = router.query;
		delete routerItems["slug"];

		if (Object.keys(routerItems).length) {
			if (routerItems["gclid"]) {
				routerItems["GCLID__c"] = routerItems["gclid"];
			}
			cookieCutter.set("urlParams", JSON.stringify(routerItems), {
				expires: expireDate,
			});
		}

		const routeChangeStart = () => {
			NProgress.start();
		};

		const routeChangeEnd = () => {
			NProgress.done();
			if (window.drift) {
				window.drift.page();
			}
		};

		addEventListener("resize", () => {
			handleResize();
		});

		router.events.on("routeChangeStart", routeChangeStart);
		router.events.on("routeChangeComplete", routeChangeEnd);
		router.events.on("routeChangeError", routeChangeEnd);
	}, [router.events, router.query, urlParams]);

	useEffect(() => {
		handleResize();
	});

	/*
		Init tag manager
	*/

	return (
		<JotaiProvider>
			<Head>
				<meta charSet="utf-8" />
				<meta httpEquiv="x-ua-compatible" content="ie=edge" />
				<meta
					name="viewport"
					content="height=device-height, width=device-width, initial-scale=1.0, minimum-scale=1.0"
				/>
				<meta name="format-detection" content="telephone=no" />

				<link rel="preconnect" href="https://abnormalsecurity-1.wistia.com/" />
				<link rel="preconnect" href="https://cmp.osano.com" />

				<meta charSet="utf-8" />

				<link
					rel="sitemap"
					type="application/xml"
					title="Sitemap"
					href="/sitemaps-1-sitemap.xml"
				/>

				<link
					rel="apple-touch-icon-precomposed"
					sizes="57x57"
					href="/apple-touch-icon-57x57.png"
				/>
				<link
					rel="apple-touch-icon-precomposed"
					sizes="114x114"
					href="/apple-touch-icon-114x114.png"
				/>
				<link
					rel="apple-touch-icon-precomposed"
					sizes="72x72"
					href="/apple-touch-icon-72x72.png"
				/>
				<link
					rel="apple-touch-icon-precomposed"
					sizes="144x144"
					href="/apple-touch-icon-144x144.png"
				/>
				<link
					rel="apple-touch-icon-precomposed"
					sizes="60x60"
					href="/apple-touch-icon-60x60.png"
				/>
				<link
					rel="apple-touch-icon-precomposed"
					sizes="120x120"
					href="/apple-touch-icon-120x120.png"
				/>
				<link
					rel="apple-touch-icon-precomposed"
					sizes="76x76"
					href="/apple-touch-icon-76x76.png"
				/>
				<link
					rel="apple-touch-icon-precomposed"
					sizes="152x152"
					href="/apple-touch-icon-152x152.png"
				/>
				<link
					rel="icon"
					type="image/png"
					href="/favicon-196x196.png"
					sizes="196x196"
				/>
				<link
					rel="icon"
					type="image/png"
					href="/favicon-96x96.png"
					sizes="96x96"
				/>
				<link
					rel="icon"
					type="image/png"
					href="/favicon-32x32.png"
					sizes="32x32"
				/>
				<link
					rel="icon"
					type="image/png"
					href="/favicon-16x16.png"
					sizes="16x16"
				/>
				<link
					rel="icon"
					type="image/png"
					href="/favicon-128.png"
					sizes="128x128"
				/>
				<meta name="application-name" content="&nbsp;" />
				<meta name="msapplication-TileColor" content="#FFFFFF" />
				<meta name="msapplication-TileImage" content="/mstile-144x144.png" />
				<meta
					name="msapplication-square70x70logo"
					content="/mstile-70x70.png"
				/>
				<meta
					name="msapplication-square150x150logo"
					content="/mstile-150x150.png"
				/>
				<meta
					name="msapplication-wide310x150logo"
					content="/mstile-310x150.png"
				/>
				<meta
					name="msapplication-square310x310logo"
					content="/mstile-310x310.png"
				/>

				<meta name="apple-mobile-web-app-title" content="Abnormal Security" />
				<meta name="application-name" content="Abnormal" />
				<meta name="apple-mobile-web-app-status-bar-style" content="white" />
				<meta name="format-detection" content="telephone=no" />
			</Head>

			{process.env.NODE_ENV === "production" && (
				<>
					<Script id="osano" strategy="lazyOnload">
						{`var script = document.createElement('script');
              script.src = 'https://cmp.osano.com/169lRDSj6676CDt8D/88b78aa1-c424-4eeb-96cb-59809bf84390/osano.js';
              document.getElementsByTagName('body')[0].appendChild(script);`}
					</Script>

					<Script id="datalayer" strategy="lazyOnload">
						{`window.dataLayer = window.dataLayer ||[];
                function gtag(){dataLayer.push(arguments);}
                gtag('consent','default',{
                'ad_storage':'denied',
                'analytics_storage':'denied' 
                });
                gtag("set", "ads_data_redaction", true);`}
					</Script>

					<Script id="gtm" strategy="lazyOnload">
						{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-5DSW3JC');`}
					</Script>
				</>
			)}

			<div className="flex flex-col justify-between h-screen">
				<SiteHeader
					navigationItems={pageProps.navigation?.primaryNavigation}
					callToActions={pageProps.navigation?.primaryCallToActions}
					globalSet={pageProps.globalSet}
				/>

				<div
					className={clsx(
						"SiteHeader__siteMenu",
						"fixed inset-x-0 top-0 z-40 hidden lg:block"
					)}
				>
					<SiteMenu items={pageProps.navigation?.primaryNavigation} />
				</div>

				<TargetLayout {...pageProps}>
					<Component {...pageProps} err={err} />
				</TargetLayout>

				<SiteFooter
					primaryNavigation={pageProps.navigation?.footerPrimaryNavigation}
					secondaryNavigation={pageProps.navigation?.footerSecondaryNavigation}
					globalSet={pageProps.globalSet}
				/>
			</div>
			<div className={clsx("block xl:hidden")}>
				<SiteMobileMenu
					items={pageProps.navigation?.primaryNavigation}
					callToActions={pageProps.navigation?.primaryCallToActions}
					globalSet={pageProps.globalSet}
				/>
			</div>
		</JotaiProvider>
	);
}
export default MyApp;
