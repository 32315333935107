import clsx from "clsx";
import { useAtomValue } from "jotai";
import { headerHeightAtom } from "@atoms/siteHeader";
import DotBorder from "@components/DotBorder";

const HeaderBuffer = ({ hideHeaderBuffer = false }) => {
	const headerHeight = useAtomValue(headerHeightAtom);

	return (
		<>
			<div
				className="header-buffer relative"
				style={{
					height: headerHeight ? headerHeight + 3 : 0,
				}}
			>
				{!hideHeaderBuffer && <DotBorder bottomOnly={true} />}
			</div>
		</>
	);
};

export default HeaderBuffer;
