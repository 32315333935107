import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import clsx from "clsx";
import { useAtom } from "jotai";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";

import { isSiteMobileMenuActiveAtom } from "@atoms/siteHeader";
import { ChevronDown } from "@components/Icons";
import PrimaryButton from "@components/PrimaryButton";
import { Disclosure, Transition } from "@headlessui/react";
import { getHref } from "@lib/routes";
import { useAtomValue } from "jotai/utils";
import { headerHeightAtom } from "@atoms/siteHeader";
import { AnimatePresence, motion } from "framer-motion";
import EmbeddedForm from "@components/EmbeddedForm";
import Message from "@components/Message";
import ReactHtmlParser from "react-html-parser";

const SiteMobileMenu = ({ items, callToActions, globalSet }) => {
	const router = useRouter();

	const [isFormActive, setIsFormActive] = useState(false);

	function toggleForm() {
		setIsFormActive(!isFormActive);
	}

	const [formSuccess, setFormSuccess] = useState(false);

	const handleFormSuccess = () => {
		setFormSuccess(true);
	};

	const scrollTargetRef = useRef<HTMLDivElement>(null);

	const [isScrollTargetRefVisible, setIsScrollTargetRefVisible] =
		useState(false);

	const [isSiteMobileMenuActive, setIsSiteMobileMenuActive] = useAtom(
		isSiteMobileMenuActiveAtom
	);

	const headerHeight = useAtomValue(headerHeightAtom);

	/*
		Close menu when route changes
	*/
	useEffect(() => {
		const routeChangeStart = () => {
			setIsSiteMobileMenuActive(false);
		};

		router.events.on("routeChangeStart", routeChangeStart);
		router.events.on("routeChangeError", routeChangeStart);
	}, [router, setIsSiteMobileMenuActive]);

	useEffect(() => {
		const targetElement = scrollTargetRef.current;

		if (!isScrollTargetRefVisible) return;

		if (isSiteMobileMenuActive) disableBodyScroll(targetElement);
		if (!isSiteMobileMenuActive) enableBodyScroll(targetElement);
	}, [isSiteMobileMenuActive, isScrollTargetRefVisible]);

	const parentItems = items?.filter((i) => i.level === 1) || [];

	return (
		<Transition
			show={isSiteMobileMenuActive}
			className={clsx("SiteMobileMenu", "fixed inset-0 z-40")}
		>
			<Transition.Child
				className={clsx(
					"SiteMobileMenu__content",
					"bg-black-01",
					"pb-15 pt-20",
					"flex flex-col",
					"absolute left-0 top-0 bottom-0 right-0 md:left-auto",
					"md:w-1/2"
				)}
				enter="transition-all duration-500 ease-out"
				enterFrom="opacity-0 translate-x-full"
				enterTo="opacity-100 translate-x-0"
				leave="transition-all duration-500 ease-out"
				leaveFrom="opacity-100 translate-x-0"
				leaveTo="opacity-0 translate-x-full"
				style={{ paddingTop: headerHeight }}
			>
				<div
					className={clsx(
						"SiteMobileMenu__items relative",
						"h-full",
						"overflow-y-scroll no-scrollbar",
						"w-full max-w-md bg-white",
						"p-7.5 md:p-15 pb-30"
					)}
					ref={(el) => {
						scrollTargetRef.current = el;
						setIsScrollTargetRefVisible(!!el);
					}}
				>
					{parentItems.map((item) => {
						const href = item.target.element
							? getHref(item.target.element)
							: item.target.url;
						const target = !item.target.element ? "_blank" : null;

						const childItems =
							items?.filter((i) => i.parent?.id === item.id) || [];

						return (
							<Disclosure key={item.id}>
								{({ open }) => (
									<>
										<div
											className={clsx("flex items-center", {
												"mb-3": childItems.length == 0 || !open,
											})}
										>
											{!!href && (
												<Link
													href={
														item.target.element?.uri
															? "/" + item.target.element.uri
															: item.target.url
													}
												>
													<a
														className={clsx(
															"text-white-01",
															"text-2xl md:text-3xl"
														)}
														target={target}
													>
														{item.title}
													</a>
												</Link>
											)}
											{!href && (
												<div className="navItem__container">
													{childItems.length > 0 && (
														<Disclosure.Button
															className={clsx(
																"text-white-01",
																"flex items-center justify-center"
															)}
														>
															<a
																className={clsx(
																	"text-white-01",
																	"text-2xl md:text-3xl"
																)}
																target={target}
															>
																{item.title}
															</a>
															<div
																className={clsx("ml-3", {
																	"rotate-180": open,
																})}
															>
																<ChevronDown />
															</div>
														</Disclosure.Button>
													)}
												</div>
											)}
										</div>
										<Transition
											enter="transition ease-out duration-500"
											enterFrom="transform opacity-0 translate-x-14"
											enterTo="transform opacity-100 translate-x-0"
											leave="transition ease-out duration-300"
											leaveFrom="transform opacity-100 translate-x-0"
											leaveTo="transform opacity-0 translate-x-14"
										>
											<Disclosure.Panel>
												{item.overviewLink.url && (
													<Link href={item.overviewLink.url}>
														<a
															className={clsx(
																"text-white-01",
																"py-3",
																"text-sm",
																"px-7.5",
																"block"
															)}
														>
															{item.overviewLink.text}
														</a>
													</Link>
												)}
												{!!childItems && (
													<div className={clsx("flex flex-col")}>
														{childItems.map((childItem) => {
															const subChildItems =
																items?.filter(
																	(i) => i.parent?.id === childItem.id
																) || [];
															return (
																<div
																	key={childItem.id}
																	className="childItem__container mb-5"
																>
																	{subChildItems.length > 1 && (
																		<a
																			className={clsx(
																				"text-blue-03",
																				"py-0",
																				"px-0",
																				"block",
																				{
																					"mt-3": !item.overviewLink.url,
																				}
																			)}
																		>
																			{childItem.title}
																		</a>
																	)}

																	{subChildItems.map((subChildItem) => {
																		const href = subChildItem.target.element
																			? getHref(subChildItem.target.element)
																			: subChildItem.target.url;
																		return (
																			<Link
																				key={subChildItem.id}
																				href={!!href ? href : ""}
																			>
																				<a
																					className={clsx(
																						"text-white-01",
																						"py-3",
																						"text-sm",
																						"px-7.5",
																						"block"
																					)}
																				>
																					{subChildItem.title}
																				</a>
																			</Link>
																		);
																	})}
																</div>
															);
														})}
													</div>
												)}
											</Disclosure.Panel>
										</Transition>
									</>
								)}
							</Disclosure>
						);
					})}
				</div>

				{!!callToActions && (
					<div
						className={clsx(
							"SiteMobileMenu__callToActions",
							"w-full",
							"bg-black-01",
							"px-7.5 md:px-15 pt-7.5",
							"absolute bottom-0",
							"mt-auto"
						)}
					>
						{callToActions.map((callToAction) => {
							return (
								<div
									key={callToAction.id}
									onClick={toggleForm}
									className={clsx(
										"SiteMobileMenu__callToAction",
										"block mb-7.5"
									)}
								>
									<PrimaryButton
										target={{
											element: callToAction.element,
											type: "entry",
											text: callToAction.title,
										}}
										isFull
									/>
								</div>
							);
						})}
					</div>
				)}
				<AnimatePresence>
					{isFormActive && (
						<>
							<motion.div
								initial={{ height: 0, opacity: 0 }}
								exit={{ height: 0, opacity: 0 }}
								animate={{ height: 150, opacity: 1 }}
								className={clsx(
									"bg-white-02 py-5 text-center absolute w-full bottom-0 mt-auto z-10"
								)}
							>
								{!formSuccess && (
									<>
										<div className="FormDescription relative z-10">
											{ReactHtmlParser(globalSet.formDescription)}
										</div>
										<div className="FormContainer relative w-full mt-5 flex z-10">
											<EmbeddedForm
												form={globalSet.headerForm[0]}
												onSuccess={handleFormSuccess}
											/>
										</div>
									</>
								)}
								<AnimatePresence>
									{formSuccess && (
										<Message message="We've received your submission!" />
									)}
								</AnimatePresence>
							</motion.div>
							<div
								className="SiteMenu__overlay fixed inset-0 z-09"
								onClick={toggleForm}
							></div>
						</>
					)}
				</AnimatePresence>
			</Transition.Child>
		</Transition>
	);
};

export default SiteMobileMenu;
