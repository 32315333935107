export const fetchForm = async (formId) => {
  let formData = {};

  try {
    const formResponse = await fetch(`${process.env.NEXT_PUBLIC_CMS_URL}/api/getForm`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ formId }),
    });

    formData = await formResponse.json();

    // Might have errored, so just return null
    if (formData.status === 500) {
      return null;
    }

    return formData;
  } catch (err) {
    return err;
  }
};

export default fetchForm;
