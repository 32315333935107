// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { BrowserTracing } from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://fc7702bf93b54791b3db39afff8f6020@o212762.ingest.sentry.io/6251112',
  // Adjust this value in production, or use tracesSampler for greater control
  ignoreErrors: ['Non-Error promise rejection captured'],
  integrations:
    [
      new BrowserTracing({
        tracingOrigins: ['cms.abnormalsecurity.com', 'marketing-cms.test']
      }),
      new CaptureConsoleIntegration()
    ],
  tracesSampleRate: 1,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
