import clsx from "clsx";
import { useRouter } from "next/router";
import { useRef, useState, useEffect, useCallback } from "react";
import { Field, Form } from "react-final-form";
import ReactHtmlParser from "react-html-parser";

import fetchForm from "@lib/fetchForm";

import cookieCutter from "cookie-cutter";
import { NIL } from "uuid";
import { ArrowRight } from "./Icons";
import { IncomingWebhook } from "@slack/webhook";

const stringyTypes = ["text", "telephone", "email", "password"];

const EmbeddedForm = ({
	form,
	formData = null,
	formValues = null,
	overrideButtonText = null,
	transparentInputs = false,
	outlineButton = false,
	inline = false,
	onSuccess = () => null,
	buttonTextColor,
	legacyButtonBackgroundColor,
	redirect = true,
}) => {
	const [targetFormData, setTargetFormData] = useState(formData);

	// Use a state variable to track whether the form has been submitted successfully+2
	const [formSuccessfullySubmitted, setFormSuccessfullySubmitted] =
		useState(false);

	const [countryValue, setCountryValue] = useState("");

	const [showState, setShowState] = useState(false);
	let formDetails;
	if (formValues) {
		formDetails = formValues;
	} else {
		formDetails = form.formDetails[0];
	}

	const [urlParams, setUrlParams] = useState(null);

	const router = useRouter();

	useEffect(() => {
		if (Object.keys(router.query).length) {
			setUrlParams(router.query);
		} else if (cookieCutter.get("urlParams")) {
			setUrlParams(JSON.parse(cookieCutter.get("urlParams")));
		}
	}, [router.query]);

	const getForm = async (formId) => {
		const formData = await fetchForm(formId);

		return await formData;
	};

	// If we haven't been provided formData, we need to fetch it
	if (!targetFormData) {
		// Use the form id specified by the selected form in the pageEntry (if there is one)
		const formId = formDetails.formId;

		if (formId) {
			getForm(formId).then((data) => setTargetFormData(data));
		}
	}

	const formRef = useRef(null);

	const buttonText = overrideButtonText ? overrideButtonText : form.buttonText;

	const isCompanyEmail = (value) =>
		value &&
		(value.includes("@gmail.") ||
			value.includes("@hotmail.") ||
			value.includes("@live.") ||
			value.includes("@yahoo.") ||
			value.includes("@outlook.") ||
			value.includes("@aol."))
			? "Please use a company email address"
			: null;

	// If formDetails isn't defined just return null
	if (!formDetails) return null;

	const updateCountryValue = (e) => {
		if (e == "United States") {
			setShowState(true);
		} else {
			setShowState(false);
		}
		setCountryValue(e);
	};

	// TODO: handle the form submission
	const onSubmit = async (values) => {
		// await sleep(300);
		const postData = {};
		postData["fields"] = values;
		postData["formId"] = formDetails.formId;
		const postJson = JSON.stringify(postData);

		const post = await fetch(
			`${process.env.NEXT_PUBLIC_CMS_URL}/api/submitForm`,
			{
				body: JSON.stringify(postData),
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		const result = await post.json();

		const webhookUrl =
			"https://hooks.slack.com/services/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";

		const webhook = new IncomingWebhook(webhookUrl);
		delete webhook["axios"].defaults.headers["User-Agent"];
		webhook["axios"].defaults.headers["Content-Type"] =
			"application/x-www-form-urlencoded";

		if (result.success === true) {
			webhook.send({
				attachments: [
					{
						color: "#00ff00",
						blocks: [
							{
								type: "header",
								text: {
									type: "plain_text",
									text: `Form Submission: ${form.title}`,
									emoji: true,
								},
							},
							{
								type: "section",
								text: {
									type: "mrkdwn",
									text: `*Result:* ${
										result.result[0].status
									}\n*Reason:*\n\`\`\`${JSON.stringify(
										result.result[0]?.reasons
									)}\`\`\``,
								},
							},
							{
								type: "section",
								text: {
									type: "mrkdwn",
									text: `*Form Data:*\n\`\`\`${JSON.stringify(postData)}\`\`\``,
								},
							},
							{
								type: "section",
								text: {
									type: "mrkdwn",
									text: `*Referral URL:* ${window.location.href}\n\n<https://app-sj31.marketo.com/leadDatabase/loadLeadDetail?leadId=${result.result[0].id}|View Lead in Marketo>`,
								},
							},
						],
					},
				],
			});
		} else {
			webhook.send({
				attachments: [
					{
						color: "#ff0000",
						blocks: [
							{
								type: "header",
								text: {
									type: "plain_text",
									text: "Website Form Submission Failed",
									emoji: true,
								},
							},
							{
								type: "section",
								text: {
									type: "mrkdwn",
									text: `*Form Name*: ${form.title} \n*Referral URL:* ${window.location.href}`,
								},
							},
							,
							{
								type: "section",
								text: {
									type: "mrkdwn",
									text: `\`\`\`${JSON.stringify(postData)}\`\`\`\``,
								},
							},
						],
					},
				],
			});
		}

		console.log(result);

		if (result.success === true) {
			window.dataLayer?.push({
				event: "event",
				eventProps: {
					category: "Form",
					action: "Form Fill",
					label: form.title,
					value: window.location.href,
				},
			});

			// Update state
			setFormSuccessfullySubmitted(true);
			//console.log(result);

			// Update the url shallowly
			if (redirect) {
				router.push(`${router.asPath}?form=success`, undefined, {
					shallow: true,
				});
			}

			// Fire the callback fn
			onSuccess();
		} else {
			router.push(`${router.asPath}?form=error`, undefined, { shallow: true });

			//TODO: display and handle errors
		}

		// TODO: alternatively, fire an event to Google Tag Manager test
	};

	// const fieldsData = formData.filter((field) => field.dataType !== "htmltext"); // filter out anything we don't want to render
	//console.log(targetFormData);

	return (
		<div className={clsx("EmbeddedForm", { "w-full": inline })}>
			{!!targetFormData && targetFormData.length && (
				<Form
					onSubmit={onSubmit}
					formDetails={formDetails}
					render={({ handleSubmit, form, submitting, pristine, values }) => (
						<form
							onSubmit={handleSubmit}
							ref={formRef}
							className={clsx("EmbeddedForm__form", {
								"md:flex": inline,
							})}
						>
							{targetFormData.map((fieldData) => {
								const isStringy = stringyTypes.find((t) =>
									fieldData.dataType.includes(t)
								);

								const isSelect = fieldData.dataType === "select";
								const isEmail = fieldData.dataType === "email";
								const isCheckbox = fieldData.dataType === "checkbox";
								const isHidden = fieldData.dataType === "hidden";

								return (
									<Field
										key={fieldData.id}
										name={fieldData.id}
										validate={isEmail ? isCompanyEmail : null}
										initialValue={
											urlParams && !!urlParams[fieldData.id]
												? urlParams[fieldData.id]
												: formDetails[fieldData.id]
												? formDetails[fieldData.id]
												: fieldData.autoFill
												? fieldData.autoFill.value
												: ""
										}
									>
										{({ input, meta }) => (
											<div
												className={clsx("EmbeddedForm__field", "mb-4", {
													grow:
														inline &&
														fieldData.dataType !== "hidden" &&
														fieldData.dataType !== "htmltext",
												})}
											>
												{fieldData.dataType == "htmltext" && (
													<div
														className={clsx("EmbeddedForm__htmlText", "mb-3")}
													>
														{ReactHtmlParser(fieldData.text)}
													</div>
												)}
												{isStringy &&
													fieldData.dataType != "htmltext" &&
													fieldData.dataType != "textArea" && (
														<div
															className={clsx(
																"EmbeddedForm__field",
																"mb-4",
																"text-black-01",
																{
																	"text-white-01": transparentInputs,
																}
															)}
														>
															<label
																className={clsx(
																	"EmbeddedForm__labelWrapper",
																	"block",
																	"bg-white-01",
																	{
																		"bg-white-01/10 text-white-01":
																			transparentInputs,
																	}
																)}
															>
																<div
																	className={clsx(
																		"EmbeddedForm__label",
																		"text-xs",
																		"opacity-50",
																		"px-4 pt-2",
																		"text-current",
																		{
																			hidden: inline,
																		}
																	)}
																>
																	{fieldData.label}
																</div>
																<input
																	{...input}
																	required={fieldData.required}
																	type={
																		fieldData.dataType === "telephone"
																			? "tel"
																			: fieldData.dataType
																	}
																	placeholder={inline ? fieldData.label : null}
																	className={clsx(
																		"EmbeddedForm__input",
																		"form-input",
																		"w-full",
																		{ "pt-0": !inline },
																		{ "h-15": inline },
																		"bg-transparent"
																	)}
																/>
															</label>

															{meta.error && meta.touched && (
																<span
																	className={clsx(
																		"EmbeddedForm__error p-1.5 block text-red text-xs italic"
																	)}
																>
																	{meta.error}
																</span>
															)}
														</div>
													)}
												{isStringy && fieldData.dataType === "textArea" && (
													<div
														className={clsx(
															"EmbeddedForm__field",
															"mb-4",
															"text-black-01",
															{
																"text-white-01": transparentInputs,
															}
														)}
													>
														<label
															className={clsx(
																"EmbeddedForm__labelWrapper",
																"block",
																"bg-white-01",
																{
																	"bg-white-01/10 text-white-01":
																		transparentInputs,
																}
															)}
														>
															<div
																className={clsx(
																	"EmbeddedForm__label",
																	"text-xs",
																	"opacity-50",
																	"px-4 pt-2",
																	"text-current",
																	{
																		hidden: inline,
																	}
																)}
															>
																{fieldData.label}
															</div>
															<textarea
																{...input}
																required={fieldData.required}
																placeholder={inline && fieldData.label}
																rows={4}
																className={clsx(
																	"EmbeddedForm__input",
																	"form-input",
																	"w-full",
																	{ "pt-0": !inline },
																	{ "h-15": inline },
																	"bg-transparent"
																)}
															/>
														</label>

														{meta.error && meta.touched && (
															<span
																className={clsx(
																	"EmbeddedForm__error p-1.5 block text-red text-xs italic"
																)}
															>
																{meta.error}
															</span>
														)}
													</div>
												)}
												{isHidden && (
													<input
														{...input}
														required={fieldData.required}
														type="hidden" // TODO: show for now, for testing
														className={clsx(
															"EmbeddedForm__hiddenInput",
															"form-input",
															"w-full",
															"bg-white-02"
														)}
													/>
												)}
												{isSelect && (
													<div>
														<label
															className={clsx(
																"EmbeddedForm__field",
																"block",
																"bg-white-01",
																"text-black-01",
																{
																	"bg-white-01/50": transparentInputs,
																}
															)}
														>
															{((fieldData.id == "state" && showState) ||
																fieldData.id != "state") && (
																<div
																	className={clsx(
																		"EmbeddedForm__labelWrapper",
																		"text-xs",
																		"opacity-50",
																		"px-4 pt-2",
																		"bg-white-01"
																	)}
																>
																	{fieldData.label}
																</div>
															)}

															{fieldData.id == "country" && (
																<select
																	{...input}
																	value={countryValue}
																	onChange={(e) => {
																		updateCountryValue(e.target.value);
																	}}
																	required={fieldData.required}
																	className={clsx(
																		"EmbeddedForm__country",
																		"form-select",
																		"w-full",
																		"text-black-01"
																	)}
																>
																	{fieldData.fieldMetaData.values.map(
																		(option) => {
																			return (
																				<option
																					key={option.value}
																					value={option.value}
																				>
																					{option.label}
																				</option>
																			);
																		}
																	)}
																</select>
															)}

															{showState && fieldData.id == "state" && (
																<select
																	{...input}
																	required={fieldData.required}
																	className={clsx(
																		"EmbeddedForm__state",
																		"form-select",
																		"w-full",
																		"text-black-01"
																	)}
																>
																	{fieldData.fieldMetaData.values.map(
																		(option) => {
																			return (
																				<option
																					key={option.value}
																					value={option.value}
																				>
																					{option.label}
																				</option>
																			);
																		}
																	)}
																</select>
															)}

															{fieldData.id != "country" &&
																fieldData.id != "state" && (
																	<select
																		{...input}
																		required={fieldData.required}
																		className={clsx(
																			"EmbeddedForm__select",
																			"form-select",
																			"w-full",
																			"text-black-01"
																		)}
																	>
																		{fieldData.fieldMetaData.values.map(
																			(option) => {
																				return (
																					<option
																						key={option.value}
																						value={option.value}
																					>
																						{option.label}
																					</option>
																				);
																			}
																		)}
																	</select>
																)}
														</label>

														{meta.error && meta.touched && (
															<span>{meta.error}</span>
														)}
													</div>
												)}
												{isCheckbox && (
													<div>
														<label className={clsx("flex items-center")}>
															<input
																{...input}
																required={fieldData.required}
																type={fieldData.dataType}
																className={clsx("form-checkbox")}
															/>
															<div className={clsx("block", "ml-4")}>
																{ReactHtmlParser(fieldData.label)}
															</div>

															{meta.error && meta.touched && (
																<span>{meta.error}</span>
															)}

															{meta.error && meta.touched && (
																<span>{meta.error}</span>
															)}
														</label>
													</div>
												)}
											</div>
										)}
									</Field>
								);
							})}

							<div
								className={clsx("EmbeddedForm__buttons", {
									shrink: inline,
								})}
							>
								<button
									style={{
										backgroundColor: legacyButtonBackgroundColor,
										color: buttonTextColor,
									}}
									type="submit"
									disabled={submitting || formSuccessfullySubmitted}
									className={clsx(
										"EmbeddedForm__button",
										"flex items-center justify-center md:ml-auto w-full md:w-auto",
										"h-15 px-5  group",
										{
											"bg-black-01":
												!legacyButtonBackgroundColor && !outlineButton,
											"border hover:bg-white-01 hover:text-black-01 hover:border-white-01":
												outlineButton,
											"text-white-01": !buttonTextColor,
										},
										"rounded-sm"
									)}
								>
									{submitting
										? "Submitting..."
										: formSuccessfullySubmitted
										? "Success!"
										: buttonText
										? buttonText
										: "Submit"}
									<div
										className={clsx(
											"EmbeddedForm__buttonIcon",
											"ml-4",
											"transition-transform group-hover:translate-x-1"
										)}
									>
										<ArrowRight />
									</div>
								</button>
							</div>
						</form>
					)}
				/>
			)}
		</div>
	);
};

export default EmbeddedForm;
