import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";

import Logo from "@components/Logo";
import footerGrid from "@images/footerGrid.png";
import { NavigationItem } from "@interfaces/NavigationItem.interface";
import getHref from "@lib/routes";
import FooterPrimaryNavigation from "@modules/FooterPrimaryNavigation";
import ReactHtmlParser from "react-html-parser";
import EmbeddedForm from "@components/EmbeddedForm";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useMeasure } from "react-use";
import Message from "@components/Message";

interface IProps {
	primaryNavigation: NavigationItem[];
	secondaryNavigation: NavigationItem[];
	globalSet: any[];
}

const SiteFooter = ({
	primaryNavigation,
	secondaryNavigation,
	globalSet,
}: IProps): JSX.Element => {
	const now = new Date();
	const currentYear = now.getFullYear();

	const [ref, bounds] = useMeasure();
	const router = useRouter();

	const [isFormActive, setIsFormActive] = useState(false);
	function toggleForm() {
		setIsFormActive(!isFormActive);
	}

	const [formSuccess, setFormSuccess] = useState(false);

	const handleFormSuccess = () => {
		setFormSuccess(true);
	};

	return (
		<footer className={clsx("SiteFooter", "bg-black-01", "relative", "w-full")}>
			<div
				className={clsx(
					"SiteFooter__content",
					"flex flex-col",
					"max-w-screen-2xl mx-auto",
					"lg:px-7.5"
				)}
			>
				<div
					className={clsx(
						"SiteFooter__main",
						"md:flex items-start md:flex-col xl:flex-row",
						"pb-15",
						"relative",
						"z-20"
					)}
				>
					<div
						className={clsx(
							"SiteFooter__statement",
							"text-white-01",
							"hidden md:block w-full max-w-md md:max-w-md lg:max-w-md",
							"px-7.5 md:px-15 lg:px-7.5"
						)}
					>
						<Link href="/">
							<div className={clsx("lg:w-[500px] pt-16")}>
								<Logo />
								<div className={clsx("pt-10 max-w-[250px] text-sm")}>
									<p>Providing Unique Insight into Emerging Attacks</p>
								</div>
							</div>
						</Link>
					</div>
					<div className="SiteFooter__MobileLogo block md:hidden text-center pt-10 flex justify-center">
						<Logo />
					</div>

					{!!primaryNavigation && (
						<div
							className={clsx(
								"SiteFooter__footerPrimaryNavigation",
								"px-7.5 md:px-15 pt-15 lg:px-7.5",
								"w-full"
							)}
						>
							<FooterPrimaryNavigation items={primaryNavigation} />
						</div>
					)}
				</div>

				<div className={clsx("flex flex-wrap items-start justify-end")}>
					{!formSuccess && (
						<>
							<div className="FormDescription column relative text-white-01 z-10 text-xl max-w-md pt-2 pr-5 pl-5 lg:pl-0 pb-10 lg:pb-0">
								<p>Get the Latest from Abnormal Intelligence</p>
							</div>

							<div className="FormContainer column relative w-full  max-w-md z-10">
								{globalSet?.headerForm && (
									<EmbeddedForm
										form={globalSet?.headerForm[0]}
										onSuccess={handleFormSuccess}
										inline={true}
										legacyButtonBackgroundColor="#6863F2"
										buttonTextColor="#fff"
									/>
								)}
							</div>
						</>
					)}
					<AnimatePresence>
						{formSuccess && (
							<Message message={globalSet?.headerForm[0].successMessage} />
						)}
					</AnimatePresence>
				</div>

				<div className={clsx("SiteFooter__secondary", "pb-7.5 lg:pb-15")}>
					<div className={clsx("SiteFooter__footer")}>
						<div
							className={clsx(
								"SiteFooter__base",
								"flex flex-col-reverse xl:flex-row xl:items-center",
								"py-15 md:py-7.5 xl:py-15"
							)}
						>
							<div
								className={clsx(
									"SiteFooter__copyright",
									"text-white-01 text-xs",
									"px-7.5 md:px-15 lg:px-7.5"
								)}
							>
								©{currentYear} Abnormal Security Corp. All rights reserved.
							</div>
							{!!secondaryNavigation && (
								<div
									className={clsx(
										"SiteFooter__secondaryNavigation",
										"mb-7.5 md:mb-15 xl:mb-0 xl:ml-auto",
										"space-y-4 xl:space-y-0 xl:space-x-4",
										"px-7.5 md:px-15 lg:px-7.5 xl:px-none"
									)}
								>
									{secondaryNavigation.map((item) => {
										const href = item.element
											? getHref(item.element)
											: item.url;

										const target = !item.element ? "_blank" : null;

										const isActive = href === router.asPath;

										return (
											<Link key={item.id} href={href}>
												<a
													href={href}
													target={target}
													className={clsx(
														"text-white-01 text-xs block md:w-1/3 md:inline-block xl:w-auto"
													)}
												>
													{item.title}
												</a>
											</Link>
										);
									})}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default SiteFooter;
